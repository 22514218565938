import {isFilled} from '@prismicio/client'
import {SliceZone} from '@prismicio/react'
import {ButtonDocument, HomePageDocument, NavigationDocumentData, ProductDeepDiveDocumentData} from 'prismicio-types'
import {memo} from 'react'
import {useEffectOnce} from 'react-use'
import {components} from 'slices'
import {Layout} from 'src/common/Layout'
import {Meta} from 'src/common/Meta'
import {EVENTS, track} from 'src/common/helpers'
import {Context} from 'src/common/types'

export const Home = memo(function Home(props: {
  navigationData: NavigationDocumentData
  page: HomePageDocument
  productDeepDiveData: ProductDeepDiveDocumentData
  buttonsData: ButtonDocument[]
}) {
  useEffectOnce(() => {
    track({
      event: EVENTS.PAGE_LOAD,
      pageName: 'homepage',
      mktgId: `${props.page.uid}-page-viewed`,
    })
  })

  return (
    <Layout
      alternateLanguages={props.page.alternate_languages.map((altLang) => ({...altLang, uid: ''}))}
      breadcrumbs={props.page.data.breadcrumbs}
      navigationData={props.navigationData}
      isDarkNavigation={props.page.data.is_dark_navigation || false}
      pageUid="home-page"
      locale={props.page.lang}
    >
      <Meta
        {...(isFilled.keyText(props.page.data.meta_description) && {description: props.page.data.meta_description})}
        {...(isFilled.keyText(props.page.data.meta_title) && {title: props.page.data.meta_title})}
        {...(isFilled.image(props.page.data.meta_image) && {imageUrl: props.page.data.meta_image.url})}
      />

      <SliceZone
        components={components}
        context={
          {
            pageUid: 'home-page',
            product_deep_dive_data: props.productDeepDiveData,
            buttonsData: props.buttonsData,
          } as Context
        }
        slices={props.page.data.slices}
      />
    </Layout>
  )
})
